'use strict';
/**
 * @name UsersAddUserCtrl
 * @desc Controller for users details
 */
function UsersAddUserCtrl($scope, $q, $resource, $state, Users, toastr, companiesForAdminUse,
                          $uibModal, globalFunc, companyCostCenters, $rootScope, UserPermissions,
                          userCategories, currenciesList, timezones, adminSettings) {

  $scope.isSourcingEnabled = !!$rootScope.isSourcingSupplierEnabled;

  $scope.duplicationCheck = duplicationCheck;
  $scope.generatePassword = generatePassword;
  $scope.validateNumbers = validateNumbers;
  $scope.selectedEmailFrequency = selectedEmailFrequency;
  $scope.onSelectCategory = onSelectCategory;
  $scope.onSelectLocale = onSelectLocale;
  $scope.onSelectTimezone = onSelectTimezone;
  $scope.onSelectCurrency = onSelectCurrency;
  $scope.randomPassword = false;
  $scope.submitted = false;
  $scope.userCategories = userCategories;
  $scope.currencies = currenciesList;
  $scope.timezones = timezones;
  $scope.isV2FeatureEnabled = isV2FeatureEnabled;
  $scope.isMulticased = false;
  $scope.isNumbered = false;
  $scope.isSymboled = false;

  $scope.locales = [
    {
      'code' : 'en_MY',
      'descr': 'English - Malaysia'
    },
    {
      'code' : 'zh_CN',
      'descr': 'Chinese - China'
    }
  ];

  $scope.addUser = addUser;

    $scope.options = {
    companies: companiesForAdminUse,
    user_group: [],
    languages: [],
    timezones: [],
    countries: []
  };

  $scope.emailFrequency = [
    {descr: 'Immediate', value: true},
    {descr: 'End of Day', value: false}
  ];

  function selectedEmailFrequency(value){
    $scope.user.receive_email_immediate = value;
  }

  $scope.submitted = false;

  $scope.user = {};
  //TODO: remove redundant variables (Ahmed Saleh, 10/07/2017)
  $scope.deselectedCC = {};

  $scope.message_error = {};

  $scope.selected_options = {
    companies:[],
    user_group: [],
    languages: [],
    timezones: [],
    countries: []
  };

  //load countries
  $scope.loadCountries = function () {
    //return $scope.loadOptionsSimple(pathConstants.apiUrls.companies.list, 'countries');

    $scope.options['countries'] = globalFunc.countryCode();

//    $scope.selected_options['country'] = {selected: $scope.options.countries[0]};
  };

  $scope.hasUserPermission = UserPermissions.hasPermission($scope.$root.currentUser, 'Users', 'Create');

  function selectCostCenter(selected_company){
    $uibModal.open({
      templateUrl: "app/usersManagement/add/user-costcenter-modalTemplate.html",
      backdrop: 'static',
      keyboard: false,
      controller: userCostCenterCtrl,
      scope: $scope,
      resolve:{
        userCostCenters: function($q,companyCostCenters){
          var deferred = $q.defer();
          companyCostCenters.get(
            {
              id: selected_company._id
            },
            function (resource) {
              if (!!resource.content) {
                deferred.resolve(resource.content.data);
              }
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
  })
  }

  var companyValidate;
  var phoneValidate;

  $scope.validateInputData = function(){
  companyValidate = true;
  phoneValidate = true;

    //validate password
    if (!$scope.user.password || !$scope.user.password.length)
      return false;
    if (!$scope.user.name)
      return false;
    if (!$scope.selected_options.country || (!!$scope.selected_options.country && !$scope.selected_options.country.selected))
      return false;
    if (!$scope.user.category)
      return false;
    if ($scope.user.category === 'Partner' && !$scope.user.subCategory)
      return false
    if (_.isEmpty($scope.user.locale))
      return false
    if (_.isEmpty($scope.user.timezone))
      return false
    if (_.isEmpty($scope.user.currency))
      return false

    return true;
  };

  $scope.cancelAddUser = function(){
    $state.go('main.user.users.manage', {status:'active'});
  };

  //upload user detail
  function addUser (){
    $scope.submitted = true;

    if(!$scope.validateInputData()){
      toastr.error('Please fill all required fields');
      return;
    }
    if(!$scope.isValidPhoneNo || !$scope.isValidHandPhoneNo){
      toastr.error('Please enter a valid phone number format.');
      return;
    }

    var submit_data = {
      display_name: $scope.user.name,
      email: $scope.user.email,
      send_email: $scope.user.send_email,
      password: $scope.user.password,
      //TODO: remove safely (Ahmed Saleh, 10/07/2017)
      img_url: 'http://lorempixel.com/128/128/people/?16748',
      receive_email_immediate: $scope.user.receive_email_immediate,
      emp_no: $scope.user.employee_number,
      config: {
        lang: 'en',
        locale: $scope.user.locale.code,
        TZ: $scope.user.timezone,
        country: $scope.selected_options.country.selected.descr
      },
      phone: $scope.user.phone,
      category: $scope.user.category,
      sub_category: $scope.user.subCategory || null,
      hand_phone: $scope.user.handPhone || null,
      currency: $scope.user.currency.code
    };

    Users.post(
      {},
    submit_data,
      function () {
        toastr.success('User has been added successfully');
        $state.go('main.user.users.manage', {status: 'Active'});
      },
      function (error) {
        $scope.submitted = false;
        globalFunc.objectErrorMessage(error);
      });
  }

  function generatePassword(){
    $scope.user.password = globalFunc.generateRandomPasswordV2($scope.passwordSettings);
    $scope.randomPassword = true;
    $scope.user.send_email = true;
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'emailCheckTrue':
          $scope.emailCheck = true;
          break;
        case 'empCheckTrue':
          $scope.empCheck = true;
          break;
        case 'emailCheckFalse':
          $scope.emailCheck = false;
          break;
        case 'empCheckFalse':
          $scope.empCheck = false;
          break;
      }
    });
  }

  function onSelectCategory(type, value) {
    if (type === 'main') {
      $scope.user.category = value;

      if (value !== 'Partner') {
        $scope.user.subCategory = null;
      }
    }

    if (type === 'sub') {
      $scope.user.subCategory = value;
    }
  }

  function onSelectLocale(value) {
    $scope.user.locale = value;
  }

  function onSelectTimezone(value) {
    $scope.user.timezone = value;
  }

  function onSelectCurrency(value) {
    $scope.user.currency = value;
  }

  function loadPasswordSettings() {
    adminSettings.get(
      {},
      function(resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.passwordSettings = resource.content.data.config.password;
          $scope.regexPassword = globalFunc.generateRegexPassword($scope.passwordSettings);
        }
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function checkFields(value, field) {
    var reg;
    var test = false;
    switch (field) {
      case 'MULTICASE':
        reg = new RegExp("(?=.*[a-z])(?=.*[A-Z])")
        test = reg.test(value);
        break;
      case 'NUMBERS':
        reg = new RegExp("(?=.*[0-9])")
        test = reg.test(value);
        break;
      case 'SYMBOLS':
        reg = new RegExp("(?=.*[@#$!%*?&])")
        test = reg.test(value);
        break;
    }
    return test;
  }

  function initialize(){
    var tabData = {
      tab: $scope.$parent.tabData[1],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
    $scope.isValidPhoneNo = true;
    $scope.isValidHandPhoneNo = true;
    $scope.user.locale = $scope.locales[0];
    $scope.user.send_email = isV2FeatureEnabled() ? false : true;
    loadPasswordSettings();
  }

  /**
   * Validate number format with given element id and returns boolean in field name
   *
   * @param {string} elementId
   * @param {string} fieldName
   */
  function validateNumbers(elementId, fieldName) {
    $scope[fieldName] = globalFunc.numberFormatValidation(elementId);
  }

  function isV2FeatureEnabled() {
    return !!$rootScope.isV2Enabled;
  }

  $scope.$watch('user_detail_form.user_password.$viewValue', function (newValue, oldValue) {
    $scope.isMulticased = checkFields(newValue, 'MULTICASE')
    $scope.isNumbered = checkFields(newValue, 'NUMBERS')
    $scope.isSymboled = checkFields(newValue, 'SYMBOLS')
  });

  initialize();
}

UsersAddUserCtrl.$inject = ['$scope', '$q', '$resource', '$state', 'Users', 'toastr', 'companiesForAdminUse',
                            '$uibModal', 'globalFunc', 'companyCostCenters', '$rootScope', 'UserPermissions',
                            'userCategories', 'currenciesList', 'timezones', 'adminSettings'];

angular
  .module('metabuyer')
  .controller('UsersAddUserCtrl', UsersAddUserCtrl);
