'use strict';

/**
 * @name UsersDetailCtrl
 * @desc Controller for user detail
 */
function UsersDetailCtrl($scope, $uibModal, $state, profile, toastr, singleUser, pathConstants, uploadImage,
                         $auth, $rootScope, positionAssignment, HighlightId, globalFunc, roleAssignmentsData,
                         companiesList, userApprovalTrack, $filter, UserPermissions, loadCacheableData,
                         resetUsersApprovalTracks, timezones, userCategories , $timeout, adminSettings ) {

  $scope.isUserAccessManager = globalFunc.findRoleInRoleAssignments($scope.$root.currentUser.role_assignments, 'User Access Manager');
  $scope.isSourcingEnabled = !!$rootScope.isSourcingSupplierEnabled;

  $scope.user = profile;
  $scope.clonedUser = angular.copy($scope.user);
  $scope.viewCostCenter = viewCostCenter;
  $scope.viewRoles = viewRoles;
  $scope.user.CClist = [];
  $scope.user.roleList = [];
  $scope.back = back;
  $scope.checkLockedUser = checkLockedUser;
  $scope.changeUserStatus = changeUserStatus;
  $scope.navigateToEditRoleAssignments = navigateToEditRoleAssignments;
  $scope.isUploadImageSuccess = isUploadImageSuccess;
  $scope.selectFontWeight = selectFontWeight;
  $scope.selectHeaderSize = selectHeaderSize;
  $scope.beforeSaveUser = beforeSaveUser;
  $scope.saveUser = saveUser;
  $scope.updateEmailTimeDropdownVisibility = updateEmailTimeDropdownVisibility;
  $scope.updateEmailPreference = updateEmailPreference;
  $scope.loggedInUserIsDeveloper = loggedInUserIsDeveloper;
  $scope.openDatepicker = openDatepicker;
  $scope.resetAllTracks = resetAllTracks;
  $scope.isV2FeatureEnabled = isV2FeatureEnabled;
  $scope.navigateToDelegationPage = navigateToDelegationPage;
  $scope.navigateToApprovalLimitPage = navigateToApprovalLimitPage;
  $scope.onSelectLocale = onSelectLocale;
  $scope.onSelectTimezone = onSelectTimezone;
  $scope.onSelectCurrency = onSelectCurrency;
  $scope.timezones = timezones;
  $scope.onSelectCategory = onSelectCategory;
  $scope.userCategories = userCategories;
  $scope.onSelectCountry = onSelectCountry;
  $scope.hasUpdatePermission = hasUpdatePermission;
  $scope.validateNumbers = validateNumbers;
  $scope.cancelEditUser = cancelEditUser;

  $scope.userLimitTableData = [];

  $scope.locales = [
    {
      'code' : 'en_MY',
      'descr': 'English - Malaysia'
    },
    {
      'code' : 'zh_CN',
      'descr': 'Chinese - China'
    }
  ];

  function navigateToDelegationPage() {
    $state.go('main.user.userDelegation', {
      roleAssignmentsData:$scope.roleAssignmentsData,
      id: $scope.user._id
    });
  }

  function navigateToApprovalLimitPage() {
    $state.go('main.user.approvalLimit.manage', {
      id: $scope.user._id
    });
  }

  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.user._id,
      'object[0][object_type]': 'Metabuyer\\Models\\Users',
      'exclude[0]': 'new_values.role_assignments',
      'exclude[1]': 'old_values.role_assignments',
      offset: 10,
      order_by: '-created_at' // Created at, descending
    }
  };

  $scope.emailFrequency = [
    { descr: 'Immediate', value: true, id: 1 },
    { descr: 'End of Day', value: false, id: 2 }
  ];

  $scope.isEditMode = false;
  $scope.listCostCenters = [];
  $scope.companyToAdd = {};
  $scope.positionAssign = null;

  // setting for cost center multi-select dropdown list
  $scope.settingCostCenter = {
    dynamicTitle: false,
    smartButtonMaxItems: 1,
    scrollable: true,
    idProp: '_id',
    externalIdProp: '',
    displayProp: 'descr',
    enableSearch: true,
    enableSortOnTop: true,
    buttonClasses: 'btn btn-white'
  };

  if (!$scope.user.status) {
    $scope.user.status = '';
  }

  if (!$scope.user.role) {
    $scope.user.role = '';
  }

  $scope.emailPreferenceOptions = [
    { label: 'Immediate', value: true, id: 1 },
    { label: 'Delayed', value: false, id: 2 }
  ];

  $scope.delayedEmailTimeOptions = [
    { label: 'End of Day', value: [17], id: 1 },
    { label: 'Midday and End of Day', value: [13, 17], id: 2 }
  ];

  $scope.emailPreference = { selected: null };
  $scope.delayedEmailTime = { selected: null };
  $scope.showEmailTimeDropdown = false;

  loadCacheableData.loadData({
    module: 'currencies',
    'criteria[is_active]': 1,
    offset: 0
  }, 'currencies', 'localStorage').then(function(data){
    $scope.currencyList = data;
    $scope.user.currency = _.find($scope.currencyList, function(curr){
      return curr.code === $scope.user.currency.code;
    })
  });

  $scope.countryList = globalFunc.countryCode();

  function loggedInUserIsDeveloper() {
    return (!!UserPermissions.isDeveloper($rootScope.currentUser));
  }

  function updateEmailTimeDropdownVisibility(selectedEmailPreference) {
    // `2` is the id of `Delayed` option for email preference. see $scope.emailPreferenceOptions
    if (selectedEmailPreference.id === 2) {
      $scope.showEmailTimeDropdown = true;
    } else {
      $scope.showEmailTimeDropdown = false;
    }
  }

  function updateEmailPreference() {
    var emailTime = [];
    if (!$scope.emailPreference.selected.value) {
      emailTime = $scope.delayedEmailTime.selected.value;
    }

    var params = {
      receive_email_immediate: $scope.emailPreference.selected.value,
      email_time_preference: emailTime
    };

    singleUser.put(
      {
        id: $scope.user._id
      },
      params,
      function () {
        toastr.success('User updated successfully.');
      },
      function () {
        toastr.error('something went wrong');
      })
    .$promise
    .then($timeout(function (resource) {
        $rootScope.$broadcast('reloadAuditData');
    }, 3000));
  }

  function viewCostCenter(listCostCenters) {
    $uibModal.open({
      templateUrl: 'app/usersManagement/detail/showCostCenters-modalTemplate.html',
      backdrop: 'static',
      keyboard: false,
      controller: showCostCenterCtrl,
      resolve: {
        listCostCenters: function () {
          return listCostCenters;
        },
        company: function () {
          return listCostCenters[0].company_name;
        }
      }
    })
  }

  function viewRoles(listRoles, company) {
    $uibModal.open({
      templateUrl: 'app/usersManagement/detail/showRoles-modalTemplate.html',
      backdrop: 'static',
      keyboard: false,
      controller: showRolesCtrl,
      resolve: {
        listRoles: function () {
          return listRoles;
        },
        company: function () {
          return company.descr;
        }
      }
    })
  }

  function back() {
    if (!!$scope.user && !!$scope.user._id) {
      HighlightId.setId($scope.user._id);
    }
    window.history.back();
  }

  function checkLockedUser() {
    $uibModal.open({
      templateUrl: 'app/usersManagement/detail/admin-unlocking-user-panelTemplate.html',
      backdrop: 'static',
      keyboard: false,
      controller: adminUnlockUserCtrl,
      scope: $scope
    })
  }

  /*
   * The user is submitted and pending approval.

   PENDING = 0;

   /**
   * The user is active (Approved)

   ACTIVE = 1;

   /**
   * The user is deactivated

   INACTIVE = 2;

   /**
   * The user was not approved

   REJECTED = 3;
   */
  function changeUserStatus(newStatus) {
    if (newStatus.toUpperCase() === 'INACTIVE') {
      swal({
        title: 'User Deactivation',
        text: 'Confirm user deactivation?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      },
        function (isConfirm) {
          if (isConfirm) {
            singleUser.put(
              {
                id: $scope.user._id,
                status: 2
              },
              function (resource) {
                $scope.user.status = 'INACTIVE';
              },
              function (error) {
                toastr.error('Failed to deactivate the user.');
              })
            .$promise
            .then($timeout(function (resource) {
                $rootScope.$broadcast('reloadAuditData');
            }, 3000));
          }
        });
    }
    else if (newStatus.toUpperCase() === 'ACTIVE') {
      swal({
        title: 'User Activation',
        text: 'Confirm user activation?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1AB394',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      },
        function (isConfirm) {
          if (isConfirm) {
            singleUser.put(
              {
                id: $scope.user._id,
                status: 1
              },
              function (resource) {
                $scope.user.status = 'ACTIVE';
              },
              function (error) {
                toastr.error('Failed to activate the user.');
              })
              .$promise
              .then($timeout(function (resource) {
                  $rootScope.$broadcast('reloadAuditData');
              }, 3000));
          }
        });
    }
  }

  /**
   * Navigating to edit role assignments state and send resolved data as state parameters
   */
  function navigateToEditRoleAssignments() {
    globalFunc.setDataByKey($scope.roleAssignmentsData, 'roleAssignments');
    globalFunc.setDataByKey($scope.companiesList, 'companiesList');
    $state.go('main.user.users.editRoleAssignments', { id: $scope.user._id, user: $scope.user });
  }

  // config flowjs
  $scope.flowConfig = new Flow({
    target: uploadImage.uploadImagePath($scope.user._id),
    singleFile: true,
    testChunks: false,
    chunkSize: 10000000,
    headers: {
      'Authorization': 'Bearer ' + $auth.getToken()
    }
  });

  function isUploadImageSuccess(file, message) {
    if (message) {
      var response = JSON.parse(message);
      toastr.success(response.content.message);
    }
  }

  function selectFontWeight(value) {
    singleUser.put(
      {
        id: $scope.user._id
      },
      {
        font_weight: value
      },
      function () {
        toastr.success('Font weight updated.');
        profile.config.font_weight = value;
      }
    )
    .$promise
    .then($timeout(function (resource) {
        $rootScope.$broadcast('reloadAuditData');
    }, 3000));
  }

  function selectHeaderSize(value) {
    singleUser.put(
      {
        id: $scope.user._id
      },
      {
        pr_header_size: value
      },
      function () {
        toastr.success('PR header size updated.');
        profile.config.pr_header_size = value;
      }
    )
    .$promise
    .then($timeout(function (resource) {
        $rootScope.$broadcast('reloadAuditData');
    }, 3000));
  }

  // reset back to before data edit
  function cancelEditUser() {
    $scope.user = angular.copy($scope.clonedUser);
    $scope.user.country = _.find($scope.countryList, function(country){
      return country.descr === $scope.clonedUser.config.country;
    });
    $scope.user.locale = _.find($scope.locales, function(locale){
      return locale.code === $scope.clonedUser.config.locale;
    });
  }

  function beforeSaveUser() {
    // $scope.clonedUser = _.cloneDeep($scope.user);
  }

  function saveUser() {
    $scope.submitted = true;

    // if(!validateInputData()) {
    //   toastr.error('Please fill all required fields');
    //   $scope.submitted = false;
    //   return;
    // }

    if (!$scope.isV2FeatureEnabled() && _.isEmpty($scope.user.phone)) {
      toastr.error('Please fill up the phone number.');
      cancelEditUser();
      return;
    }
    if (!$scope.isV2FeatureEnabled() && $scope.user.category === 'Partner' && _.isEmpty($scope.user.sub_category)) {
      toastr.error('Sub Category is required when selected Category `Partner`.');
      cancelEditUser();
      return;
    }

    if(!$scope.isValidPhoneNo || !$scope.isValidHandPhoneNo){
      toastr.error('Please enter a valid phone number format.');
      cancelEditUser();
    }

    var submit_data = {
      display_name: $scope.user.display_name,
      emp_no: !!$scope.user.emp_no ? $scope.user.emp_no : '',
      currency_code: !!$scope.user.currency ? $scope.user.currency.code : undefined,
      timezone: !!$scope.user.timezone ? $scope.user.timezone : undefined,
      locale: !!$scope.user.locale ? $scope.user.locale.code : undefined,
      phone: !!$scope.user.phone ? $scope.user.phone : undefined,
      hand_phone: !!$scope.user.hand_phone ? $scope.user.hand_phone : undefined,
      category: !!$scope.user.category ? $scope.user.category : undefined,
      sub_category: !!$scope.user.sub_category ? $scope.user.sub_category : undefined,
      country: !!$scope.user.country ? $scope.user.country.descr : undefined,
    };

    singleUser.put(
      {
        id: $scope.user._id
      },
      submit_data,
      function () {
        $scope.clonedUser = angular.copy($scope.user);
        toastr.success('User updated successfully.');
      },
      function (error) {
        cancelEditUser();
        globalFunc.objectErrorMessage(error);
      })
    .$promise
    .then($timeout(function (resource) {
        $rootScope.$broadcast('reloadAuditData');
    }, 3000));
  }

  function getPosition() {
    positionAssignment.get(
      {
        'sort[0][key]': 'updated_at',
        'sort[0][direction]': 'DESC',
        'filter_groups[1][filters][0][key]': 'assignee_id',
        'filter_groups[1][filters][0][value]': $scope.user._id,
        'filter_groups[1][filters][0][operator]': 'ct',
        'filter_groups[2][filters][0][key]': 'status',
        'filter_groups[2][filters][0][value]': 0,
        'filter_groups[2][filters][0][operator]': 'ct',
        limit: 0,
        page: 0
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.positionAssign = resource.content.data;
        }
      },
      function () {
        toastr.error('Failed to retrieve position information.');
      }
    )
    .$promise
    .then($timeout(function (resource) {
        $rootScope.$broadcast('reloadAuditData');
    }, 3000));
  }

  function getSelectedEmailTime(timeArray) {
    var selectedEmailTime = $scope.delayedEmailTimeOptions[0];

    $scope.delayedEmailTimeOptions.forEach(function (option) {
      if (_.isEqual(timeArray, option.value)) {
        selectedEmailTime = option;
      }
    });

    return selectedEmailTime;
  }

  /**
   * For date picker
   *
   * @param {Object} $event
   * @param {string} which - start or end date
   */
  function openDatepicker($event, which) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!!$scope.user.end_date) {
      $scope.startDateOptions.maxDate = $scope.user.end_date;
    }

    $scope.endDateOptions.minDate = new Date();

    $scope.datepickerOpened[which] = true;
  }

  function loadPasswordSettings() {
    adminSettings.get(
      {},
      function(resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.passwordSettings = resource.content.data.config.password;
          $scope.regexPassword = globalFunc.generateRegexPassword($scope.passwordSettings);
        }
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function initialize() {
    $scope.isValidPhoneNo = true;
    $scope.isValidHandPhoneNo = true;
    $scope.roleAssignmentsData = roleAssignmentsData;
    $scope.companiesList = companiesList;
    $scope.isAdmin = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Admin');

    $state.params.status = !!$scope.user.status ? $scope.user.status.toLowerCase() : 'all';

    if (!!$scope.user.receive_email_immediate) {
      $scope.activeRadio = 1;
    }
    else {
      $scope.activeRadio = 2;
    }

    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);

    $scope.fontWeights = [
      { descr: 'Normal', value: 'normal' },
      { descr: 'Bold', value: 'bold' }
    ];

    $scope.prHeaderSizes = [
      { descr: 'Normal', value: 'normal' },
      { descr: 'Minimal', value: 'mini' }
    ];

    if (!!$scope.user && !!$scope.user.config && !_.isUndefined($scope.user.config.font_weight)) {
      $scope.selectedFontWeight = globalFunc.findInArray(
        $scope.fontWeights,
        'value',
        $scope.user.config.font_weight
      );
    }

    if (!!$scope.user && !!$scope.user.config && !_.isUndefined($scope.user.config.pr_header_size)) {
      $scope.selectedHeaderSize = globalFunc.findInArray(
        $scope.prHeaderSizes,
        'value',
        $scope.user.config.pr_header_size
      );
    }

    // Check if user.companies is defined
    if (!$scope.user.companies || angular.isUndefined($scope.user.companies)) {
      $scope.user.companies = [];
    }

    // Get the roles and cost centers(if any)
    _.forEach($scope.user.companies, function (company) {
      var roleAssignments = globalFunc.findInArray($scope.user.role_assignments, 'organization_id', company._id);
      if (!!roleAssignments) {
        company.roles = roleAssignments.roles;
      }

      var costCenters = _.filter($scope.user.costCenters, function (costCenter) {
        return costCenter.company_code === company.code;
      });
      company.costCenters = !_.isEmpty(costCenters) ? costCenters :
        [{ code: company.code === 'system' ? 'N/A' : 'All' }];
    });

    $scope.superiorCatalog = {};
    $scope.superiorCatalog.descr = 'Catalog';
    $scope.superiorCatalog.code = 'Catalog';
    $scope.superiorCatalog._id = 'catalog';

    $scope.superiorSupplier = {};
    $scope.superiorSupplier.descr = 'Supplier';
    $scope.superiorSupplier.code = 'Supplier';
    $scope.superiorSupplier._id = 'supplier';

    // Only push Catalog and Supplier if user has superior
    _.forEach($scope.user.managers, function ($value) {
      if ($value['company_code'] === 'Catalog' && !!$value['display_name']) {
        $scope.roleAssignmentsData.push($scope.superiorCatalog);
      }

      if ($value['company_code'] === 'Supplier' && !!$value['display_name']) {
        $scope.roleAssignmentsData.push($scope.superiorSupplier);
      }
    });

    if (!!($scope.user)) {
      if (!!$scope.user.receive_email_immediate) {
        $scope.emailPreference = { selected: globalFunc.findInArray($scope.emailPreferenceOptions, 'value', true) };
      } else {
        $scope.emailPreference = { selected: globalFunc.findInArray($scope.emailPreferenceOptions, 'value', false) };
        $scope.showEmailTimeDropdown = true;
      }

      if (!!$scope.user.email_time_preference) {
        $scope.delayedEmailTime = { selected: getSelectedEmailTime($scope.user.email_time_preference) };
      } else {
        $scope.delayedEmailTime = { selected: $scope.delayedEmailTimeOptions[0] };
      }
    }

    $scope.imageBasePath = pathConstants.apiUrls.image.user;

    getPosition();

    $scope.user.locale = _.find($scope.locales, function(locale){
      return locale.code === $scope.user.config.locale;
    });

    $scope.user.country = _.find($scope.countryList, function(country){
      return country.descr === $scope.user.config.country;
    });

    loadPasswordSettings();
  }

  function resetAllTracks() {
    swal(
      {
        title: 'Confirm delete All tracks',
        text: 'All user\'s tracks will be deleted this is Not reversible',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Delete',
        closeOnConfirm: true
      },
      function (isConfirm) {
        if (isConfirm) {
          resetUsersApprovalTracks.delete({},
            function success() {
              toastr.success('Accomplished');
            },
            function error(err) {
              globalFunc.objectErrorMessage(err);
            });
        }
      }
    );
  }

  function isV2FeatureEnabled() {
    return $rootScope.isV2Enabled;
  }

  function onSelectLocale(value) {
    $scope.user.locale = value;
  }

  function onSelectTimezone(value) {
    $scope.user.timezone = value;
  }

  function onSelectCurrency(value) {
    $scope.user.currency = value;
  }

  function onSelectCategory(type, value) {
    if (type === 'main') {
      $scope.user.category = value;

      if (value !== 'Partner') {
        $scope.user.sub_category = null;
      }
    }

    if (type === 'sub') {
      $scope.user.sub_category = value;
    }
  }

  function onSelectCountry(value) {
    $scope.user.country = value;
  }

  /**
   * Validate number format with given element id and returns boolean in field name
   *
   * @param {string} elementId
   * @param {string} fieldName
   */
   function validateNumbers(elementId, fieldName) {
    $scope[fieldName] = globalFunc.numberFormatValidation(elementId);
  }

  function hasUpdatePermission() {
    return UserPermissions.checkPermissionsAccess($rootScope.currentUser, 'Users', 'Update');
  }

  initialize();

}

UsersDetailCtrl.$inject = [
  '$scope', '$uibModal', '$state', 'profile', 'toastr', 'singleUser', 'pathConstants', 'uploadImage', '$auth',
  '$rootScope', 'positionAssignment', 'HighlightId', 'globalFunc', 'roleAssignmentsData', 'companiesList',
  'userApprovalTrack', '$filter', 'UserPermissions', 'loadCacheableData', 'resetUsersApprovalTracks',
  'timezones','userCategories', '$timeout', 'adminSettings'
];

angular
  .module('metabuyer')
  .controller('UsersDetailCtrl', UsersDetailCtrl);
