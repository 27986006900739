'use strict';

/**
 * @name userProfileCtrl
 * @desc Controller for user profile
 */
function userProfileCtrl($scope, $uibModal, $state, globalFunc, $auth, profile, Account, toastr, pathConstants,
                         $rootScope, ManageLocalStorage, updateProfile, supplierWebUser, UserPermissions, supplier,
                         currencies, supplierFunctions, roleAssignmentsData, companiesList, loggedInUserApprovalTrack,
                         $filter, loadCacheableData, $translate, $translatePartialLoader) {
  $scope.loading = false;
  $scope.removeCachedData = removeCachedData;
  $scope.selectFontWeight = selectFontWeight;
  $scope.selectPrHeaderSize = selectPrHeaderSize;
  $scope.isUploadImageError = isUploadImageError;
  $scope.delegatee = {};
  $scope.viewCostCenter = viewCostCenter;
  $scope.viewRoles = viewRoles;
  $scope.showEditProfileModal = showEditProfileModal;
  $scope.currencies = currencies;
  $scope.businessNatures = supplierFunctions.getSupplierBusinessNatureList();
  $scope.businessEntities = supplierFunctions.getSupplierBusinessEntityList();
  $scope.updateEmailTimeDropdownVisibility = updateEmailTimeDropdownVisibility;
  $scope.updateEmailPreference = updateEmailPreference;
  $scope.isV2FeatureEnabled = isV2FeatureEnabled;
  $scope.loadApprovalFlowLimitTableData = loadApprovalFlowLimitTableData;
  $scope.navigateToDelegationPage = navigateToDelegationPage;
  $scope.navigateToApprovalLimitPage = navigateToApprovalLimitPage;
  $scope.saveUser = saveUser;
  $scope.validateNumbers = validateNumbers;
  $scope.onSelectLocale = onSelectLocale;
  $scope.onSelectCurrency = onSelectCurrency;
  $scope.cancel = cancel;

  $scope.isSourcingEnabled = !!$rootScope.isSourcingSupplierEnabled;
  $scope.selectedLocale = {};
  $scope.selectedCurrency = {};

  $scope.emailPreferenceOptions = [
    {label: 'Immediate', value: true, id: 1 },
    {label: 'Delayed', value: false, id: 2}
  ];
  var translateArr = [
    "profiles.emailPreferenceOptions.immediate",
    "profiles.emailPreferenceOptions.delayed",
  ];
  $translate(translateArr).then(function (trans) {
    $scope.emailPreferenceOptions[0].label = trans['profiles.emailPreferenceOptions.immediate'];
    $scope.emailPreferenceOptions[1].label = trans['profiles.emailPreferenceOptions.delayed'];
  });

  $scope.delayedEmailTimeOptions = [
    {label: 'End of Day', value: [17], id: 1},
    {label: 'Midday and End of Day', value: [13, 17], id: 2}
  ];

  $scope.emailPreference = {selected: null};
  $scope.delayedEmailTime = {selected: null};
  $scope.showEmailTimeDropdown = false;

  $scope.locales = [
    {
      'code' : 'en_MY',
      'descr': 'English - Malaysia'
    },
    {
      'code' : 'zh_CN',
      'descr': 'Chinese - China'
    }
  ];

  function navigateToDelegationPage() {
    $state.go('main.user.mainMenu.userDelegation', {
      roleAssignmentsData:$scope.roleAssignmentsData});
  }

  function navigateToApprovalLimitPage() {
    $state.go('main.user.mainMenu.approvalLimit.manage');
  }

  function updateEmailTimeDropdownVisibility(selectedEmailPreference) {
    // `2` is the id of `Delayed` option for email preference. see $scope.emailPreferenceOptions
    $scope.showEmailTimeDropdown = (selectedEmailPreference.id === 2);
  }

  function setAddress() {
    $scope.address = {};
    if(!!$scope.supplier && !!$scope.supplier.corresponding_address &&
      !_.isArray($scope.supplier.corresponding_address)){
      $scope.address = _.cloneDeep($scope.supplier.corresponding_address);
    }
  }

  function setBusinessEntity() {
    if(angular.isDefined($scope.supplier.business_entity)) {
      $scope.supplier.selectedBusinessEntity = globalFunc.findInArray(
        $scope.businessEntities,
        'value',
        parseInt($scope.supplier.business_entity)
      );
    }
  }

  function setBusinessNature() {
    if (angular.isDefined($scope.supplier.primary_business_nature)) {
      $scope.supplier.selectedPrimaryBusinessNature = globalFunc.findInArray(
        $scope.businessNatures,
        'shortDescr',
        $scope.supplier.primary_business_nature
      );
    }

    if (angular.isDefined($scope.supplier.secondary_business_nature)) {
      $scope.supplier.selectedSecondaryBusinessNature = globalFunc.findInArray(
        $scope.businessNatures,
        'shortDescr',
        $scope.supplier.secondary_business_nature
      );
    }
  }

  function setSupplierPreferredCurrency() {
    $scope.supplier.selectedCurrency = null;
    if(!!$scope.supplier && !!$scope.supplier.currency_code) {
      var temp = _.find($scope.currencies, function(item) {
        return $scope.supplier.currency_code == item.code;
      });

      if(!!temp){
        $scope.supplier.selectedCurrency = temp;
      }
    }
  }

  function getSupplier() {
    if (!!UserPermissions.hasSupplierWebUserRole(profile)) {
      $scope.supplier = supplier;
      setSupplierPreferredCurrency();
      setBusinessNature();
      setBusinessEntity();
      setAddress();
    } else {
      $scope.supplier = null;
    }
  }

  function showEditProfileModal() {
    $uibModal.open({
      templateUrl: 'app/user/settings/userProfile/editProfileModal.html',
      backdrop: 'static',
      keyboard: false,
      controller: function ($scope, $uibModalInstance) {
        $scope.closeModal = closeModal;
        $scope.user = profile;
        $scope.updateUserProfile = updateUserProfile;
        $scope.submitted = false;

        function closeModal() {
          $uibModalInstance.close();
        }

        // validate input
        function validInput() {
          if (!!$scope.user.display_name.length && !!$scope.user.phone.length) {
            return true;
          }
          return false;
        }

        // update user
        function updateUserProfile() {
          $scope.submitted = true;
          if (validInput()) {
            var submitData = {
              name: $scope.user.display_name,
              phone: $scope.user.phone,
              roles: globalFunc.getArrayOfKeyValues($scope.user.roles, '_id')
            };

            supplierWebUser.put(
              {
                id: $scope.user._id
              },
              submitData,
              function () {
                toastr.success('Profile updated.');
                $uibModalInstance.close();
                $state.reload();
              },
              function (err) {
                globalFunc.objectErrorMessage(err);
              });
          }
        }
      }
    });
  }

  function viewCostCenter(listCostCenters) {
    $uibModal.open({
      templateUrl: "app/usersManagement/detail/showCostCenters-modalTemplate.html",
      backdrop: 'static',
      keyboard: false,
      controller: showCostCenterCtrl,
      resolve: {
        listCostCenters: function () {
          return listCostCenters;
        },
        company: function () {
          return listCostCenters[0].company_name;
        }}
    })
  }

  function viewRoles(listRoles, company) {
    $uibModal.open({
      templateUrl: "app/usersManagement/detail/showRoles-modalTemplate.html",
      backdrop: 'static',
      keyboard: false,
      controller: showRolesCtrl,
      resolve: {
        listRoles: function () {
          return listRoles;
        },
        company: function () {
          return company.descr;
        }}
    })
  }

  function updateEmailPreference() {
    var emailTime = [];
    if (!$scope.emailPreference.selected.value) {
      emailTime = $scope.delayedEmailTime.selected.value;
    }

    var params = {
        receive_email_immediate: $scope.emailPreference.selected.value,
        email_time_preference: emailTime
      };

    updateProfile.put(
      {
        id: $scope.profile._id
      },
      params,
      function(){
        toastr.success('Email preference updated.');
        profile.receive_email_immediate = value;
      },
      function() {
        toastr.error('Something went wrong');
      }
    );
  }

  function selectFontWeight(value) {
    updateProfile.put(
      {},
      {
        font_weight: value
      },
      function(){
        toastr.success('Font weight updated.');
        profile.config.font_weight = value;
      }
    );
  }

  function selectPrHeaderSize(value) {
    updateProfile.put(
      {},
      {
        pr_header_size: value
      },
      function(){
        toastr.success('PR header size updated.');
        profile.config.pr_header_size = value;
      }
    );
  }

  function removeCachedData() {
    var translateArr = [
      "profiles.clearingCachedData",
      "profiles.clearingCachedDataMessage",
      "buttons.confirm",
      "buttons.cancel"
    ];
    $translate(translateArr).then(function (trans) {
      swal({
        title: trans["profiles.clearingCachedData"],
        text: trans["profiles.clearingCachedDataMessage"],
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: trans["buttons.confirm"],
        cancelButtonText: trans["buttons.cancel"],
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isConfirm) {
        if (isConfirm) {
          ManageLocalStorage.clearLocalStorage('All');
          toastr.success('Cached data removed.');
        }
      });
    });
  }

  function isUploadImageError(file, message, flow){
    var error;
    try{
      error = JSON.parse(message);
    }catch (e){
      error = 'Error occurred while uploading'
    }
    globalFunc.objectErrorMessage(error);
  }

  $scope.isUploadImageSuccess = function (file, message, flow) {
    if (!!message) {
      toastr.success('Image updated successfully.');
      loadNewProfileData();
    }
  };

  function loadNewProfileData(){
    Account.getProfile()
      .then(function (data) {
        if ((data.status === 404)) {
          $auth.logout();
          $state.go('login');
        }
        if (!!data && !!data.content && !!data.content.data) {
          $scope.user = data.content.data;
          $rootScope.currentUser = $scope.user;
        }
      })
      .catch(function (error) {
        if (error) {
          console.error(error);
          $auth.logout();
          $state.go('login');
        }
      });
  }

  // handle company & cost center
  $scope.companies = [];
  $scope.handleGroup = function(objects){
    var groups = [];

    for(var index in objects){
      var obj = objects[index];
      var group = undefined;

      for(var i in groups){
        if(obj.company_code === groups[i].code){
          group = groups[i];
          break;
        }
      }

      if(group){
        group.costCenters.push(obj);
      } else {
        groups.push({
          code: obj.company_code,
          name: obj.company_name,
          costCenters: [obj]
        })
      }
    }

    return groups;
  };

  function getSelectedEmailTime(timeArray) {
    var selectedEmailTime = $scope.delayedEmailTimeOptions[0];
    $scope.delayedEmailTimeOptions.forEach(function (option) {
      if (_.isEqual(timeArray, option.value)) {
        selectedEmailTime = option;
      }
    });

    return selectedEmailTime;
  }

  function initialize() {
    $scope.isValidPhoneNo = true;
    $scope.roleAssignmentsData = roleAssignmentsData;
    $scope.companiesList = companiesList;

    $scope.imageBasePath = pathConstants.apiUrls.image.user + '/';
    $scope.profile = _.cloneDeep(profile);

    $scope.fontWeights = [
      {descr: 'Normal', value: 'normal'},
      {descr: 'Bold', value: 'bold'}
    ];

    $scope.prHeaderSizes = [
      {descr: 'Normal', value: 'normal'},
      {descr: 'Minimal', value: 'mini'}
    ];

    if (!!($scope.profile)) {
      if (!!profile.receive_email_immediate){
        $scope.emailPreference = {selected: globalFunc.findInArray($scope.emailPreferenceOptions, 'value', true)};
      } else {
        $scope.emailPreference = {selected: globalFunc.findInArray($scope.emailPreferenceOptions, 'value', false)};
        $scope.showEmailTimeDropdown = true;
      }
      var translateArr = [
        "profiles.emailPreferenceOptions.immediate",
        "profiles.emailPreferenceOptions.delayed",
      ];
      $translate(translateArr).then(function (trans) {
        if (!!$scope.emailPreference.selected.value) {
          $scope.emailPreference.selected.label = trans['profiles.emailPreferenceOptions.immediate'];
        } else {
          $scope.emailPreference.selected.label = trans['profiles.emailPreferenceOptions.delayed'];
        }
      });

      if (!!$scope.profile.email_time_preference) {
        $scope.delayedEmailTime = {selected: getSelectedEmailTime($scope.profile.email_time_preference)};
      } else {
        $scope.delayedEmailTime = {selected: $scope.delayedEmailTimeOptions[0]};
      }

      if (!!$scope.profile.config) {
        if (!_.isUndefined($scope.profile.config.font_weight)) {
          $scope.selectedFontWeight = globalFunc.findInArray(
            $scope.fontWeights,
            'value',
            $scope.profile.config.font_weight
          );
        }

        if (!_.isUndefined($scope.profile.config.pr_header_size)) {
          $scope.selectedPrHeaderSize = globalFunc.findInArray(
            $scope.prHeaderSizes,
            'value',
            $scope.profile.config.pr_header_size
          );
        }
      }
    }

    // config flowjs
    $scope.flowConfig = new Flow({
      target: Account.uploadImagePath($scope.profile._id),
      singleFile: true,
      testChunks:false,
      chunkSize : 10000000,
      headers: {
        'Authorization': 'Bearer ' + $auth.getToken()
      }
    });

    $scope.superiorCatalog = {};
    $scope.superiorCatalog.descr = 'Catalog';
    $scope.superiorCatalog.code = 'Catalog';
    $scope.superiorCatalog._id = 'catalog';

    $scope.superiorSupplier = {};
    $scope.superiorSupplier.descr = 'Supplier';
    $scope.superiorSupplier.code = 'Supplier';
    $scope.superiorSupplier._id = 'supplier';

    // Only push Catalog and Supplier if user has superior
    _.forEach($scope.profile.managers, function ($value) {
      if ($value['company_code'] === 'Catalog' && !!$value['display_name']) {
        $scope.roleAssignmentsData.push($scope.superiorCatalog);
      }

      if ($value['company_code'] === 'Supplier' && !!$value['display_name']) {
        $scope.roleAssignmentsData.push($scope.superiorSupplier);
      }
    });

    $scope.selectedLocale = _.find($scope.locales, function(locale){
      return locale.code === $scope.profile.config.locale;
    });
    $scope.originalLocale = _.clone($scope.selectedLocale);

    loadCacheableData.loadData({
      module: 'currencies',
      'criteria[is_active]': 1,
      offset: 0
    }, 'currencies', 'localStorage').then(function(data){
      $scope.currencyList = data;
      $scope.selectedCurrency = _.find($scope.currencyList, function(curr){
        return curr.code === $scope.profile.currency.code;
      })
      $scope.originalCurrency = _.clone($scope.selectedCurrency);
    });

    // Get supplier info
    getSupplier();
  }

  function recursiveFormatApprovalLimit(track) {
    _.forEach(track, function (v, k) {
      if (typeof (v) === 'object') {
        return recursiveFormatApprovalLimit(v);
      } else if (isFinite(v)) {
        track[k] = $filter('formatDecimal')(v, 2);
      }
    });

    return track;
  }

  function loadApprovalFlowLimitTableData() {
    loggedInUserApprovalTrack.get(
      {
        uuid: profile.uuid
      },
      function (resource) {
        if (!!resource.content) {
          var tableData = resource.content.data;
          _.forEach(tableData, function (track, index) {
            tableData[index] = recursiveFormatApprovalLimit(track);
          });
          $scope.userLimitTableData = tableData;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function isV2FeatureEnabled() {
    return $rootScope.isV2Enabled;
  }

  function validateSubmit() {
    if(!$scope.isValidPhoneNo){
      $translate("profiles.invalidPhoneNumberFormat").then(function (trans) {
        toastr.error(trans);
      });
      return false;
    }

    if($scope.isSourcingEnabled && !$scope.profile.phone.length) {
      toastr.error('Please enter phone number.');
      return false;
    }

    if(!$scope.selectedLocale) {
      toastr.error('Please select Locale.');
      return false;
    }

    if (!$scope.selectedCurrency) {
      toastr.error('Please select Currency.');
      return false;
    }

    return true;
  }

  function saveUser() {
    $scope.submitted = true;

    if (validateSubmit()) {
      $scope.profile.config.locale = $scope.selectedLocale.code;

      var submitData = {
        currency_code: $scope.selectedCurrency.code,
        config: $scope.profile.config,
        phone: !!$scope.profile.phone ? $scope.profile.phone : undefined,
      };

      updateProfile.put(
      {
        id: $scope.profile._id
      },
      submitData,
      function () {
        if (!$rootScope.isV2Enabled) {
          var lang = 'en';
          lang = $scope.selectedLocale.code === 'zh_CN' ? 'cn' : lang;
          lang = $scope.selectedLocale.code === 'en_MY' ? 'en' : lang;
          $translate.use(lang);
          $translatePartialLoader.addPart('user/mainMenu/lang');
          $translatePartialLoader.addPart('user/settings/lang');
        }
        $state.reload();
        $translate("profiles.userUpdatedSuccessfully").then(function (trans) {
          toastr.success(trans);
        });
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      });
    } else {
      cancel();
    }
  }

  /**
   * Validate number format with given element id and returns boolean in field name
   *
   * @param {string} elementId
   * @param {string} fieldName
   */
   function validateNumbers(elementId, fieldName) {
    $scope[fieldName] = globalFunc.numberFormatValidation(elementId);
  }

  function onSelectLocale(value) {
    $scope.selectedLocale = value;
  }

  function onSelectCurrency(value) {
    $scope.selectedCurrency = value;
  }

  function cancel() {
    $scope.selectedCurrency = _.clone($scope.originalCurrency);
    $scope.selectedLocale = _.clone($scope.originalLocale);
    $scope.profile.phone = profile.phone;
  }

  initialize();
}


userProfileCtrl.$inject = [
  '$scope', '$uibModal', '$state', 'globalFunc', '$auth', 'profile', 'Account', 'toastr', 'pathConstants',
  '$rootScope', 'ManageLocalStorage', 'updateProfile', 'supplierWebUser', 'UserPermissions', 'supplier',
  'currencies', 'supplierFunctions', 'roleAssignmentsData', 'companiesList', 'loggedInUserApprovalTrack', '$filter',
  'loadCacheableData', '$translate', '$translatePartialLoader'
];
angular
  .module('metabuyer')
  .controller('userProfileCtrl', userProfileCtrl);
