'use strict';

angular.module('metabuyer',
  [
    'ngAnimate',
    'ngCookies',
    'ngTouch',
    'ngSanitize',
    'ngResource',
    'ui.router',
    'ui.bootstrap',
    'xeditable',
    'ui.select',
    'satellizer',
    'angularMoment',
    'cgNotify',
    'toastr',
    'flow',
    'ct.ui.router.extras.sticky',
    'ct.ui.router.extras.dsr',
    'ui.utils',
    'smart-table',
    'mentio',
    'angular-loading-bar',
    'ui.sortable',
    'ui.router.title',
    'angularTinycon',
    'daterangepicker',
    'ngTagsInput',
    'ngPatternRestrict',
    'angular-peity',
    'isteven-multi-select',
    'NgSwitchery',
    'metabuyer.services.attachments',
    'metabuyer.services.catalogServices',
    'metabuyer.services.commentsServices',
    'custom-angularjs-dropdown-multiselect',
    'metabuyer.services.itemsCategoriesServices',
    'metabuyer.services.starringServices',
    'dropzone',
    'metabuyer.services.itemsServices',
    'metabuyer.services.taxes',
    'metabuyer.services.uoms',
    'metabuyer.services.paymentMethods',
    'metabuyer.services.sourcingExpenseType',
    'metabuyer.services.sourcingCategory',
    'metabuyer.services.sourcingProject',
    'metabuyer.services.BusinessUnits',
    'metabuyer.services.industries',
    'metabuyer.services.paymentTerms',
    'metabuyer.services.approval',
    'metabuyer.services.purchaseRequisitions',
    'metabuyer.services.users',
    'metabuyer.services.companies',
    'metabuyer.services.orders',
    'metabuyer.services.costcenters',
    'metabuyer.services.availability',
    'metabuyer.services.waitingOnUserList',
    'metabuyer.services.expenseTypes',
    'metabuyer.services.auditTrail',
    'metabuyer.services.commodity',
    'metabuyer.services.accountCodes',
    'metabuyer.services.assetCategory',
    'metabuyer.services.companyAsset',
    'metabuyer.services.notification',
    'metabuyer.services.address',
    'metabuyer.services.permissions',
    'metabuyer.services.roles',
    'metabuyer.services.escalation',
    'metabuyer.services.notes',
    'metabuyer.services.reports',
    'metabuyer.services.adminSettings',
    'metabuyer.services.delegation',
    'metabuyer.services.export',
    'metabuyer.services.GRN',
    'metabuyer.services.genericSearch',
    'metabuyer.services.catalogGroup',
    'metabuyer.services.catalogCode',
    'metabuyer.services.budgets',
    'metabuyer.services.emailLog',
    'metabuyer.services.pdfPreview',
    'metabuyer.services.approvalEngine.positionManage',
    'metabuyer.services.approvalEngine.positionAssign',
    'metabuyer.services.approvalEngine.approvalTemplates',
    'metabuyer.services.approvalEngine.approvalTemplateHistory',
    'metabuyer.services.bifrost',
    'metabuyer.services.analytics',
    'metabuyer.services.minion',
    'metabuyer.services.supplierWebDashboard',
    'metabuyer.services.logViewer',
    'metabuyer.services.advancedSearch',
    'metabuyer.services.maintenance',
    'metabuyer.services.privateAccess',
    'metabuyer.services.supplier',
    'metabuyer.services.featureToggle',
    'tandibar/ng-rollbar',
    'rzModule',
    'ui.knob',
    'ngIntlTelInput',
    'bootstrapLightbox',
    'videosharing-embed',
    'duScroll',
    'metabuyer.services.roleAssignments',
    'chart.js',
    'highcharts-ng',
    'oc.lazyLoad',
    'metabuyer.services.purchaseRequisitionsV2',
    'metabuyer.services.poConsolidation',
    'metabuyer.services.quotation',
    'metabuyer.services.purchasePrice',
    'metabuyer.services.itemMaster',
    'metabuyer.services.itemCategory',
    'metabuyer.services.stockInformationGroup',
    'metabuyer.services.catalogsServices',
    'metabuyer.services.modeOfPurchase',
    'metabuyer.services.catalogsServices',
    'metabuyer.services.alcGroup',
    'metabuyer.services.alc',
    'metabuyer.services.companyOpexIo',
    'metabuyer.services.categoryType',
    'metabuyer.services.companyOtherChargesGroup',
    'metabuyer.services.research',
    'metabuyer.services.specialEvent',
    'metabuyer.services.studentLife',
    'metabuyer.services.activities',
    'metabuyer.services.qaTools',
    'metabuyer.services.kharon',
    'metabuyer.services.appConfig',
    'metabuyer.services.itemGroup',
    'metabuyer.services.plustusServices',
    'metabuyer.services.priceComparison',
    'metabuyer.services.policyDisclaimer',
    'metabuyer.services.tender',
    'metabuyer.services.faq',
    'pascalprecht.translate'
  ])

  .config(function ($stateProvider, $urlRouterProvider, $authProvider, $httpProvider, pathConstants,
                    toastrConfig, cfpLoadingBarProvider, RollbarProvider, $locationProvider, $qProvider, ngIntlTelInputProvider,
                    $translateProvider) {

    ngIntlTelInputProvider.set({
      initialCountry: 'my',
      autoHideDialCode: true,
      nationalMode: true,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/14.0.6/js/utils.js'
    });

    $locationProvider.hashPrefix('');
    $authProvider.loginUrl = pathConstants.apiUrls.login;
    $authProvider.loginRedirect = '/user/waiting-on-you';

    // we handle the logout redirect on our own since
    // Satellizer doesn't clear the URL params when redirecting
    $authProvider.logoutRedirect = false;
    $httpProvider.defaults.headers.common['Accept'] = 'application/json';
    $httpProvider.interceptors.push('metabuyerInterceptor');

    cfpLoadingBarProvider.includeSpinner = false;
    cfpLoadingBarProvider.includeBar = true;

    /**
     * Setting up the the Rollbar
     */
    RollbarProvider.init({
      accessToken: pathConstants.RollbarConfiguration.token,
      captureUncaught: pathConstants.RollbarConfiguration.captureUncaught,
      payload: {
        environment: pathConstants.RollbarConfiguration.environment,
        build: pathConstants.RollbarConfiguration.buildNumber
      }
    });

    /**
     * Silencing the unhandled promise rejections, which is default to true
     */
    $qProvider.errorOnUnhandledRejections(false);

    /**
     * Setting up the the Translation
     */
    if (!!pathConstants.isSourcingModeEnabled) {
      $translateProvider.preferredLanguage(navigator.language);
    } else {
      $translateProvider.preferredLanguage('en');
    }
    $translateProvider.useLocalStorage();
    $translateProvider.registerAvailableLanguageKeys(['en', 'cn'], {
      'en-*': 'en',
      'cn-*': 'cn',
    });
    $translateProvider.useLoader('$translatePartialLoader', {
      urlTemplate: '/app/{part}/{lang}.json'
    });
    $translateProvider.useLoaderCache(true);
    $translateProvider.useSanitizeValueStrategy(null);

    $stateProvider
      .state('main', {
        url: '/',
        controller: 'MainCtrl',
        templateUrl: 'app/main/main.html',
        resolve: {
          authenticated: function ($q, $location, $auth, pathConstants, Rollbar) {

            /**
             * disabling Rollbar in case 'production' was not provided
             */
            if (!pathConstants.RollbarConfiguration || !pathConstants.RollbarConfiguration.environment
              || pathConstants.RollbarConfiguration.environment !== 'production') {
              Rollbar.disable();
            }

            /**
             * turn on the option for verbose errors in the console log
             */
            if(pathConstants.RollbarConfiguration && !!pathConstants.RollbarConfiguration.verbose) {
              Rollbar.verbose();
            }


            var deferred = $q.defer();
            if ($auth.isAuthenticated()) {
              deferred.resolve(true);
            } else {
              deferred.resolve(false);
            }

            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Login';
          },
          isMobileBrowser: function($rootScope, globalFunc) {
            $rootScope.isMobileBrowser = globalFunc.isMobileBrowser();
            $rootScope.isMobileMode = globalFunc.isMobileBrowser();
            return globalFunc.isMobileBrowser();
          },
          bifrostFeatureToggle: function ($q, $stateParams, FeatureToggleService) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['bifrost'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                console.log('Something went wrong');
              });

            return deferred.promise;
          },
          enableV2FeatureToggle: function ($q, FeatureToggleService, $rootScope) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['system.enableV2'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                  $rootScope.isV2Enabled = !!resource.data.is_enabled;
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                console.log('Something went wrong');
              });

            return deferred.promise;
          },
          enableGenpSuperAdminFeature: function ($q, FeatureToggleService, $rootScope) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['system.genp.admin.feature.enable'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                  $rootScope.genpAdminFeatureEnable = !!resource.data.is_enabled;
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                console.log('Something went wrong');
              });

            return deferred.promise;
          },
          enableEsourcingLogin: function ($q, FeatureToggleService, $rootScope) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['system.enablePlutusLogin'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                  $rootScope.enablePlutusLogin = !!resource.data.is_enabled;
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                console.log('Something went wrong');
              });

            return deferred.promise;
          },
          enableSourcingSupplierFeatureToggle: function ($q, FeatureToggleService, $rootScope) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['system.enableSourcingSupplier'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                  $rootScope.isSourcingSupplierEnabled = !!resource.data.is_enabled;
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                console.log('Something went wrong');
              });

            return deferred.promise;
          }
        }
      })
      .state('login', {
        url: '/login',
        controller: 'LoginCtrl',
        templateUrl: 'app/login/login.html',
        resolve: {
          authenticated: function ($q, $location, $auth, userSettings) {
            var deferred = $q.defer();

            if ($auth.isAuthenticated()) {
              $location.url('/user/waiting-on-you');
              deferred.resolve(true);
            } else {
              userSettings.loggedOut();
              deferred.resolve(false);
            }

            return deferred.promise;
          },
          policyDisclaimer: function ($q, policyDisclaimerResource) {
            var deferred = $q.defer();

            policyDisclaimerResource.get(
              {},
              function (resource) {
                deferred.resolve(resource.content.data);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                modalInstance.close();
                deferred.resolve([]);
              });

            return deferred.promise;
          },
          style: function($rootScope){
            $rootScope.bodyClasses = 'gray-bg';
          },
          $title: function ($stateParams) {
            return 'Login';
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('login/lang');
            $translatePartialLoader.addPart('help/lang');
          },
        }
      })
      .state('forgotPassword', {
        templateUrl: 'app/forgotPassword/forgotPasswordTemplate.html',
        controller: 'forgotPasswordCtrl',
        url: '/forgotPassword',
        resolve: {
          authenticated: function ($q, $location, $auth, userSettings) {
            var deferred = $q.defer();

            if ($auth.isAuthenticated()) {
              $location.url('/user/waiting-on-you');
              deferred.resolve(true);
            } else {
              userSettings.loggedOut();
              deferred.resolve(false);
            }

            return deferred.promise;
          },
          style: function($rootScope){
            $rootScope.bodyClasses = 'gray-bg';
          },
          $title: function ($stateParams) {
            return 'Password reset';
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('login/lang');
          },
        }
      })
      .state('main.explore-apps', {
        url: 'explore-apps',
        templateUrl: 'app/explore-apps/explore-apps.html',
        controller: 'ExploreAppsCtrl',
        resolve: {
          $title: function ($stateParams) {
            return "explore apps";
          }
        }
      })
      .state('passwordReset', {
        templateUrl: 'app/user/passwordReset/password-reset-template.html',
        controller: 'passwordResetCtrl',
        resolve: {
          style: function($rootScope){
            $rootScope.bodyClasses = 'gray-bg';
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('login/lang');
          },
        }
      })
      .state('accountLocked', {
        templateUrl: 'app/user/accountLocked/accountLocked.html',
        controller: 'accountLockedCtrl',
        resolve: {
          style: function($rootScope, userSettings){
            userSettings.loggedOut();
            $rootScope.bodyClasses = 'gray-bg';
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('login/lang');
          },
        }
      })
      .state('securityQuestionSetup', {
        templateUrl: 'app/user/securityQuestion/security-question-setup-template.html',
        controller: 'securityQuestionSetupCtrl',
        params: { locale: null },
        resolve: {
          style: function($rootScope){
            $rootScope.bodyClasses = 'gray-bg';
          },
          prepareTranslation: function ($stateParams, $translate, $translatePartialLoader, pathConstants) {
            if (!pathConstants.isSourcingModeEnabled) {
              $translate.use('en');
            } else {
              var locale = $stateParams.locale || null;
              if (!!locale) {
                if (locale.language === 'en' ||
                    locale.language === 'zh') {
                  $translate.use(locale.language === 'zh' ? 'cn' : locale.language);
                }
              }
            }
            $translatePartialLoader.addPart('login/lang');
          },
        }
      })
      .state('securityQuestionAnswer', {
        templateUrl: 'app/user/securityQuestion/security-question-answer-template.html',
        controller: 'securityQuestionAnswerCtrl',
        resolve: {
          style: function($rootScope){
            $rootScope.bodyClasses = 'gray-bg';
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('login/lang');
          },
        }
      })
      .state('notAuthorized', {
        templateUrl: 'app/user/notAuthorized/notAuthorized.html',
        url: '/not-authorized',
        controller: 'notAuthorizedCtrl',
        resolve: {
          style: function($rootScope){
            $rootScope.bodyClasses = 'gray-bg';
          }
        }
      })
      .state('unauthorizedBudgetAccess', {
        templateUrl: 'app/user/unauthorizedBudgetAccess/unauthorizedBudgetAccess.html',
        url: '/unauthorized-budget-access',
        controller: 'unauthorizedBudgetAccessCtrl',
        resolve: {
          style: function($rootScope){
            $rootScope.bodyClasses = 'gray-bg';
          }
        }
      })
      .state('acceptSupplierWebTnC', {
        templateUrl: 'app/user/acceptSupplierWebTnC/acceptSupplierWebTnC.html',
        url: '/accept-supplier-web-terms-and-conditions',
        controller: 'acceptSupplierWebTncCtrl',
        resolve: {
          style: function($rootScope){
            $rootScope.bodyClasses = 'gray-bg';
          }
        }
      })
      .state('invalidUrl', {
        templateUrl: 'app/user/invalidUrl/invalidUrl.html',
        url: '/invalid-url',
        controller: 'invalidUrlCtrl',
        resolve: {
          style: function($rootScope){
            $rootScope.bodyClasses = 'gray-bg';
          }
        }
      })
      .state('underMaintenance', {
        templateUrl: 'app/user/underMaintenance/underMaintenanceTemplate.html',
        url: '/underMaintenance',
        controller: 'underMaintenanceCtrl',
        resolve: {
          style: function($rootScope){
            $rootScope.bodyClasses = 'gray-bg';
          }
        }
      });
    $urlRouterProvider.otherwise('/login');

    angular.extend(toastrConfig, {
      allowHtml: true,
      closeButton: true,
      closeHtml: '<button>&times;</button>',
      extendedTimeOut: 1000,
      maxOpened: 0,
      newestOnTop: true,
      progressBar: true,
      onHidden: null,
      onShown: null,
      tapToDismiss: true,
      target: 'body',
      timeOut: 10000
    });

  })
  .run(function ($rootScope, $location, $window, cfpLoadingBar, editableOptions, editableThemes,
                 $timeout, Rollbar, pathConstants, $state, $translate) {

    //loading the init apps if any
    // metabuyerAppsLoader.initLoad();

    var url = $location.absUrl().substr(0, 4);

    // Checks if accessed url is not valid
    if (!!url && url.toLowerCase() !== 'http') {
      $state.go('invalidUrl');
    }

    /**
     * disabling Rollbar in case 'production' was not provided
     */
    var trackedEnvironments = ['production', 'stg', 'monash', 'sit', 'staging', 'development'];

    if (!pathConstants.RollbarConfiguration || !pathConstants.RollbarConfiguration.environment
      || trackedEnvironments.indexOf(pathConstants.RollbarConfiguration.environment) === -1) {
      Rollbar.disable();
    }

    $rootScope.buildNumber = (!!pathConstants.RollbarConfiguration && !!pathConstants.RollbarConfiguration.buildNumber)
      ? pathConstants.RollbarConfiguration.buildNumber : 'Not specified';

    // sets isOffline flag based on appLocation from constants
    $rootScope.isOffline = pathConstants.appLocation === 'offline' ? true : false;

    $rootScope.previousState = {
      page: {}
    };
    $rootScope.scrollPosition = {};

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      cfpLoadingBar.start();
      cfpLoadingBar.set(0.5);

      $rootScope.previousState.name = fromState.name;
      $rootScope.previousState.params = fromParams;
    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
      $rootScope.previousState.name = fromState.name;
      $rootScope.previousState.params = fromParams;

      $rootScope.actualLocation = $location.path();

      cfpLoadingBar.complete();

      //scroll fix
      $rootScope.fixPosition(toState, toParams, fromState, fromParams);
    });

    $rootScope.fixPosition = function (toState, toParams, fromState, fromParams) {
      var x = null;
      var y = null;

      if (toState.name === fromState.name) {
        if (toParams.status !== fromParams.status) {
          x = 0;
          y = 0;
        }
      } else if (fromState.name.replace(/manage|search/, "") === toState.name.replace("detail", "")) {
        $rootScope.scrollPosition[fromState.name + "_" + fromParams.status + "_" + "x"] = $window.pageXOffset;
        $rootScope.scrollPosition[fromState.name + "_" + fromParams.status + "_" + "y"] = $window.pageYOffset;
        x = 0;
        y = 0;
      } else if (fromState.name.replace("detail", "") === toState.name.replace(/manage|search/, "")) {
        if (toParams.status !== fromParams.status) {
          x = 0;
          y = 0;
        } else {
          x = $rootScope.scrollPosition[toState.name + "_" + fromParams.status + "_" + "x"] || 0;
          y = $rootScope.scrollPosition[toState.name + "_" + fromParams.status + "_" + "y"] || 0;
        }
      } else if (toState.name.replace(fromState.name, "") === ".catalog.tab") {
        $rootScope.scrollPosition[fromState.name + "_" + fromParams.status + "_" + "x"] = $window.pageXOffset;
        $rootScope.scrollPosition[fromState.name + "_" + fromParams.status + "_" + "y"] = $window.pageYOffset;
        x = 0;
        y = 0;
      } else if (fromState.name.replace(toState.name, "") === ".catalog.tab") {
        x = $rootScope.scrollPosition[toState.name + "_" + fromParams.status + "_" + "x"] || 0;
        y = $rootScope.scrollPosition[toState.name + "_" + fromParams.status + "_" + "y"] || 0;
      } else {
        x = 0;
        y = 0;
        $rootScope.scrollPosition = {};
      }

      $timeout(function () {
        if (x != null && y != null) {
          $window.scroll(x || 0, y || 0);
        }
      });
    };


    // Config xeditable
    editableOptions.theme = 'bs3';
    editableThemes.bs3.inputClass = 'input-sm';
    editableThemes.bs3.buttonsClass = 'btn-sm';

    $rootScope.$on('$translatePartialLoaderStructureChanged', function (e) {
      $translate.refresh();
    });
  });
